.bottom-tab-bar {
  bottom: 0;
  position: fixed;
  border-radius: 0 !important;
  z-index: 100;
  background-color: #0097a7 !important;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  margin-bottom: 0 !important;
}

.ui.yellow.fluid.labeled.icon.two.item.fly.up.bottom-tab-bar.visible.transition.menu {
  display: flex !important;
}

.bottom-tab {
  color: white !important;
}
