.app-bar {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  background-color: #0097a7 !important;
  border-radius: 0 !important;
  top: 0 !important;
  width: 100% !important;
  position: fixed !important;
  z-index: 100 !important;
  height: 5rem !important;
}

.header-title {
  font-size: 2rem;
  margin: 0 1rem 0 1rem;
  color: white;
  font-family: Pacifico, cursive;
}

.header-field {
  width: 25%;
}
